import swal from "sweetalert";
import {
  getFilterData,
  filterMultiple,
  singleImport,
} from "../../services/TrestleService";
import {
  GET_TRESTLE_FILTERS_REQUEST,
  MULTIPLE_TRESTLE_FILTER_IMPORT_FAILED,
  MULTIPLE_TRESTLE_FILTER_IMPORT_REQUEST,
  MULTIPLE_TRESTLE_FILTER_IMPORT_SUCCESS,
  SINGLE_TRESTLE_IMPORT_FAILED,
  SINGLE_TRESTLE_IMPORT_REQUEST,
  SINGLE_TRESTLE_IMPORT_SUCCESS,
} from "./TrestleTypes";

export function getTresetleFilterData() {
  return (dispatch, getState) => {
    getFilterData().then((response) => {
      let filterData = response.data;
      dispatch({
        type: GET_TRESTLE_FILTERS_REQUEST,
        payload: filterData.data,
      });
    });
  };
}

export function trestleMultipleImportRequest(postData, resetData) {
  return (dispatch, getState) => {
    dispatch(request());
    filterMultiple(postData)
      .then((response) => {
        if (response.status) {
          resetData();
          swal("Import", response.data.message, "success");
          dispatch(success());
        }
      })
      .catch((error) => {
        swal("Import", error.response.data.message, "error");
        dispatch(failure(error.response.data.message));
      });
  };

  function request() {
    return { type: MULTIPLE_TRESTLE_FILTER_IMPORT_REQUEST };
  }
  function success() {
    return { type: MULTIPLE_TRESTLE_FILTER_IMPORT_SUCCESS };
  }
  function failure(error) {
    return { type: MULTIPLE_TRESTLE_FILTER_IMPORT_FAILED, error };
  }
}

export function trestleSingleImportRequest(postData, resetData) {
  return (dispatch, getState) => {
    dispatch(request());
    singleImport(postData)
      .then((response) => {
        if (response.status) {
          swal("Import", response.data.message, "success");
          dispatch(success());
          resetData();
        }
      })
      .catch((error) => {
        swal("Import", error.response.data.message, "error");
        dispatch(failure(error.response.data.message));
      });
  };

  function request() {
    return { type: SINGLE_TRESTLE_IMPORT_REQUEST };
  }
  function success() {
    return { type: SINGLE_TRESTLE_IMPORT_SUCCESS };
  }
  function failure(error) {
    return { type: SINGLE_TRESTLE_IMPORT_FAILED, error };
  }
}
