import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    logoutAction,
} from '../store/actions/AuthActions';
import {URLS} from '../utils/constants';

const BASE_URL = process.env.REACT_APP_API_URL;

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export function login(email, password, deviceType = "web") {
    const postData = {
        email,
        password,
        deviceType,
    };
    return axios.post(
        BASE_URL + URLS.V1.LOGIN,
        postData,
    );
}

export function formatError(errorResponse) {
    console.log("errorResponse.message", errorResponse.message);
    swal("Oops", errorResponse.message, "error",{ button: "Try Again!",});
    // switch (errorResponse.message) {
    //     case 'EMAIL_EXISTS':
    //         //return 'Email already exists';
    //         swal("Oops", "Email already exists", "error");
    //         break;
    //     case 'EMAIL_NOT_FOUND':
    //         //return 'Email not found';
    //        swal("Oops", "Email not found", "error",{ button: "Try Again!",});
    //        break;
    //     case 'INVALID_PASSWORD':
    //         //return 'Invalid Password';
    //         swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
    //         break;
    //     case 'USER_DISABLED':
    //         return 'User Disabled';
    //     default:
    //         return errorResponse.message;
    // }
}

export function saveTokenInLocalStorage(tokenDetails) {
    // tokenDetails.expireDate = new Date(
    //     new Date().getTime() + tokenDetails.expiresIn * 1000,
    // );
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        dispatch(logoutAction(navigate));
    }, timer);
}

export function logout() {
    let userDetail = localStorage.getItem("userDetails");
    if (userDetail) {
      const { accessToken } = JSON.parse(userDetail);
      return axios.get(BASE_URL + URLS.V1.LOGOUT, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });
    }
    return;
  }

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');
    // let tokenDetails = '';
    if (!tokenDetailsString) {
        navigate('/login');
    //     dispatch(logoutAction(navigate));
        return;
    }

    /*tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(logout(navigate));
        return;
    }*/
    dispatch(loginConfirmedAction(JSON.parse(tokenDetailsString)));

    /*const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);*/
}
