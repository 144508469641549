import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";
import Select from "react-select";
import PageTitle from "../../layouts/PageTitle";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { getPropertyList } from "../../../store/actions/PropertyActions";
// import { COLUMNS } from "./Columns";
import { GlobalFilter } from "./GlobalFilter";
import { ColumnFilter } from "./ColumnFilter";
import "./filtering.css";
import CSpinner from "../PluginsMenu/Spinner/CSpinner";

export const SearchProperty = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const propertyTypes = [
    "Residential",
    "Income Property",
    "Residential Income",
    "Residential Lease",
    "Rural Residential",
    "Condo/Townhouse",
    "Multi Family",
    "Lots and Land",
    "Rentals",
    "Farm",
    "Farm/Ranch",
    "Duplex",
    "Apartments",
    "Commercial",
    "Commercial Rentals",
    "Commercial Sale",
    "Unimproved Commercial",
    "Undeterrmined",
    "Business Opportunity",
    "Manufactured In Park",
    "Model Home",
    "Proposed Construction",
    "Others",
  ];

  const [filterState, setState] = useState({
    pCity: "Denver",
    pState: "CO",
  });

  const handleChange = (event) => {
    console.log("event", event);
    const target = event.currentTarget;

    setState({
      ...filterState,
      [target.name]: target.type === "checkbox" ? target.checked : target.value,
    });
  };

  const [data, setData] = useState([]);

  const { properties, loadingProperties } = useSelector((state) => {
    return state.property;
  });

  useEffect(() => {
    dispatch(
      getPropertyList({ pCity: filterState.pCity, pState: filterState.pState })
    );
  }, []);

  useEffect(() => {
    setData(properties);
  }, [properties]);

  const COLUMNS = [
    {
      Header: "MLS Number",
      Footer: "MLS Number",
      accessor: "mlsNumberTitle",
      Filter: ColumnFilter,
      Show: false
    },
    {
      Header: "BFC ID",
      Footer: "BFC ID",
      accessor: "bfcid",
      Filter: ColumnFilter,
    },
    {
      Header: "Beds",
      Footer: "Beds",
      accessor: "beds",
      Filter: ColumnFilter,
      //disableFilters: true,
    },
    {
      Header: "Baths",
      Footer: "Baths",
      accessor: "bath",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "status",
      Filter: ColumnFilter,
    },
    {
      Header: "Sqft",
      Footer: "Sqft",
      accessor: "sqft",
      Filter: ColumnFilter,
    },
    {
      Header: "Action",
      Footer: "Action",
      Filter: false,
      Cell: ({ cell }) => (
        <span
          onClick={() => {
            handleView(cell.row.values.mlsNumberTitle);
          }}
          className="cursor-pointer"
        >
          <i className="fa fa-eye" aria-hidden="true"></i>
        </span>
      ),
    },
  ];

  const columns = useMemo(() => COLUMNS, []);
  useMemo(() => data, []);

  const handleSearch = () => {
    Object.keys(filterState).forEach(
      (key) =>
        (filterState[key] === null ||
          filterState[key] == "" ||
          filterState[key] == 0) &&
        delete filterState[key]
    );
    console.log("filters", filterState);

    dispatch(getPropertyList(filterState));
  };

  const handleView = (mlsNumber) => {
    navigate(`/property-details/${mlsNumber}`);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns:["mlsNumberTitle"] },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  return (
    <>
      <PageTitle activeMenu="Search" motherMenu="Property" />
      <CSpinner loading={loadingProperties} />
      <div className="col-xl-12 col-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">Horizontal Form</h4>
          </div>
          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row">
                  <div className="form-group mb-3 col-md-3">
                    <label>State</label>
                    <input
                      name="pState"
                      type="text"
                      defaultValue={filterState.pState}
                      className="form-control"
                      placeholder="Enter state name to search"
                      onBlur={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-3">
                    <label>City</label>
                    <input
                      name="pCity"
                      type="text"
                      defaultValue={filterState.pCity}
                      className="form-control"
                      placeholder="Enter city name to search"
                      onBlur={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-2">
                    <label>Bed</label>
                    <select
                      className="form-control"
                      id="bath"
                      name="pNumbedrooms"
                      onChange={handleChange}
                    >
                      <option value="">Please select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-2">
                    <label>Bath</label>
                    <select
                      className="form-control"
                      id="bath"
                      name="pNumbathrooms"
                      onChange={handleChange}
                    >
                      <option value="">Please select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-2">
                    <label>Lotsqize</label>
                    <input
                      name="pLotsize"
                      type="text"
                      // defaultValue={pMinprice}
                      className="form-control"
                      // placeholder="1000"
                      onBlur={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-4">
                    <label>Property Type</label>
                    <div id="multiselect" className="property-type-filter">
                      <DropdownMultiselect
                        placeholder="Select property type"
                        options={propertyTypes}
                        handleOnChange={(selected) => {
                          setState({
                            ...filterState,
                            ["pProptype"]: selected.join(","),
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-2">
                    <label>Min Price</label>
                    <input
                      name="pMinprice"
                      type="text"
                      // defaultValue={pMinprice}
                      className="form-control"
                      // placeholder="1000"
                      onBlur={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-2">
                    <label>Max Price</label>
                    <input
                      name="pMaxprice"
                      type="text"
                      // defaultValue={pMaxprice}
                      className="form-control"
                      // placeholder="999999"
                      onBlur={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-2">
                    <label>Min Sqft</label>
                    <input
                      name="pMinSqfoot"
                      type="text"
                      // defaultValue={pState}
                      className="form-control"
                      // placeholder="100"
                      onBlur={handleChange}
                    />
                  </div>
                  <div className="form-group mb-3 col-md-2">
                    <label>Max Sqft</label>
                    <input
                      name="pMaxSqfoot"
                      type="text"
                      // defaultValue={pCity}
                      className="form-control"
                      // placeholder="999"
                      onBlur={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl-4 col-xxl-6 col-6">
                    <div className="form-check custom-checkbox mb-3">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="fireplace"
                        name="pFireplace"
                        onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="fireplace">
                        Fireplace
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col-md-2 text-end">
                    <label></label>
                    <Button
                      type="submit"
                      onClick={handleSearch}
                      className="form-control btn btn-primary mt-4 cursor-pointer"
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Property Search</h4>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table {...getTableProps()} className="table  display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {column.canFilter ? column.render("Filter") : null}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button cursor-pointer"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {"<<"}
                </button>

                <button
                  className="previous-button cursor-pointer"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button cursor-pointer"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button cursor-pointer"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SearchProperty;
