import {
  formatError,
  login,
  logout,
  runLogoutTimer,
  saveTokenInLocalStorage,
  signUp,
} from "../../services/AuthService";

export const SIGNUP_CONFIRMED_ACTION = "[signup action] confirmed signup";
export const SIGNUP_FAILED_ACTION = "[signup action] failed signup";
export const LOGIN_CONFIRMED_ACTION = "[login action] confirmed login";
export const LOGIN_FAILED_ACTION = "[login action] failed login";
export const LOADING_TOGGLE_ACTION = "[Loading action] toggle loading";
export const LOGOUT_ACTION = "[Logout action] logout action";

export function signupAction(email, password, navigate) {
  return (dispatch) => {
    signUp(email, password)
      .then((response) => {
        saveTokenInLocalStorage(response.data);
        // runLogoutTimer(
        //     dispatch,
        //     response.data.expiresIn * 1000,
        //     navigate,
        // );
        dispatch(confirmedSignupAction(response.data));
        //history.push('/dashboard');
        navigate("/dashboard");
      })
      .catch((error) => {
        const errorMessage = formatError(error.response.data);
        dispatch(signupFailedAction(errorMessage));
      });
  };
}

export function logoutAction(navigate) {
  return (dispatch) => {
    logout()
      .then((response) => {
        localStorage.removeItem("userDetails");
        navigate("/login");
        dispatch({
          type: LOGOUT_ACTION,
        });
      })
      .catch((error) => {});
  };
}

export function loginAction(email, password, navigate) {
  return (dispatch) => {
    login(email, password)
      .then((response) => {
        let succssResponse = response.data;
        if (succssResponse.data.status) {
          saveTokenInLocalStorage(succssResponse.data);
          // runLogoutTimer(
          //     dispatch,
          //     succssResponse.data.expiresIn * 1000,
          //     navigate,
          // );
          dispatch(loginConfirmedAction(succssResponse.data));
          //history.push('/dashboard');
          navigate("/dashboard");
        } else {
        }
      })
      .catch((error) => {
        let errorResponse = error.response.data;
        console.log("errorResponse", errorResponse);
        if (!errorResponse.data.status) {
          const errorMessage = formatError(errorResponse);
          dispatch(loginFailedAction(errorMessage));
        }
      });
  };
}

export function loginFailedAction(data) {
  return {
    type: LOGIN_FAILED_ACTION,
    payload: data,
  };
}

export function loginConfirmedAction(data) {
  return {
    type: LOGIN_CONFIRMED_ACTION,
    payload: data,
  };
}

export function confirmedSignupAction(payload) {
  return {
    type: SIGNUP_CONFIRMED_ACTION,
    payload,
  };
}

export function signupFailedAction(message) {
  return {
    type: SIGNUP_FAILED_ACTION,
    payload: message,
  };
}

export function loadingToggleAction(status) {
  return {
    type: LOADING_TOGGLE_ACTION,
    payload: status,
  };
}
