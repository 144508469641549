import React, { Fragment, useEffect, useState } from "react";
import Select from "react-select";
import PageTItle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  getTresetleFilterData,
  trestleMultipleImportRequest,
} from "../../../store/actions/TrestleActions";
import { Button } from "react-bootstrap";
import CSpinner from "../PluginsMenu/Spinner/CSpinner";

const MultipleTresle = () => {
  const dispatch = useDispatch();
  const { trestleFilterData, trestleRequest } = useSelector((state) => {
    return state.trestle;
  });

  let errorsObj = { originatingSystem: "", trestleStatus: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedOriginSystem, setSelectedOriginSystem] = useState([]);
  const [trestleStatus, setTrestleStatus] = useState([]);
  const [originatingSystems, setOriginatingSystems] = useState([]);
  const [startDownload, setStartDownload] = useState(false);

  useEffect(() => {
    dispatch(getTresetleFilterData());
  }, []);

  useEffect(() => {
    trestleFilterData.map((option) => {
      if ("mlsstatus" in option) {
        setTrestleStatus(option.mlsstatus);
      }
      if ("originatingsystemname" in option) {
        setOriginatingSystems(option.originatingsystemname);
      }
    });
  }, [trestleFilterData]);

  const resetData = () => {
    setSelectedStatus([]);
    setSelectedOriginSystem([]);
  };

  const handleSubmit = () => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (selectedStatus.length === 0) {
      errorObj.trestleStatus = "Please select atleast one status";
      error = true;
    }
    if (selectedOriginSystem.length === 0) {
      errorObj.originatingSystem =
        "Please select atleast one originating system";
      error = true;
    }
    setErrors(errorObj);

    if (error) return;

    dispatch(
      trestleMultipleImportRequest(
        {
          MlsStatus: selectedStatus.map((status) => status.value),
          MlsType: selectedOriginSystem.map((os) => os.value),
          ImportStart: startDownload,
        },
        resetData
      )
    );
  };

  return (
    <Fragment>
      <PageTItle
        activeMenu="Multiple Trestle"
        motherMenu="Trestle"
        pageContent="Multiple Trestle"
      />
      <CSpinner loading={trestleRequest} />
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="mb-4">
                <h4 className="card-title">Trestle</h4>
              </div>
              <div className="mb-4">
                <label>Select Trestle Status:</label>
                <Select
                  key={`my_unique_status_key__`}
                  defaultValue={selectedStatus}
                  onChange={setSelectedStatus}
                  options={trestleStatus}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                  value={selectedStatus || []}
                  isMulti
                />
                {errors.trestleStatus && (
                  <div className="text-danger fs-12">
                    {errors.trestleStatus}
                  </div>
                )}
              </div>
              <div className="mb-4">
                <label>Select Originating System:</label>
                <Select
                  key={`my_unique_select_key__`}
                  defaultValue={selectedOriginSystem}
                  value={selectedOriginSystem || []}
                  onChange={setSelectedOriginSystem}
                  options={originatingSystems}
                  style={{
                    lineHeight: "40px",
                    color: "#7e7e7e",
                    paddingLeft: " 15px",
                  }}
                  isMulti
                />
                {errors.originatingSystem && (
                  <div className="text-danger fs-12">
                    {errors.originatingSystem}
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="start-download"
                    onChange={(e) => setStartDownload(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="start-download">
                    Start Download
                  </label>
                </div>
              </div>
              <div className="text-end">
                <Button onClick={handleSubmit}>Submit</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MultipleTresle;
