export const GET_FILTERS_REQUEST = "GET_FILTERS_REQUEST";

export const MULTIPLE_FILTER_IMPORT_REQUEST = "MULTIPLE_FILTER_IMPORT_REQUEST";
export const MULTIPLE_FILTER_IMPORT_SUCCESS = "MULTIPLE_FILTER_IMPORT_SUCCESS";
export const MULTIPLE_FILTER_IMPORT_FAILED = "MULTIPLE_FILTER_IMPORT_FAILED";
export const MULTIPLE_FILTER_RESET = "MULTIPLE_FILTER_RESET";

export const SINGLE_IMPORT_REQUEST = "SINGLE_IMPORT_REQUEST";
export const SINGLE_IMPORT_SUCCESS = "SINGLE_IMPORT_SUCCESS";
export const SINGLE_IMPORT_FAILED = "SINGLE_IMPORT_FAILED";
