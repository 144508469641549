import React from "react";
import { Spinner } from "react-bootstrap";

export default function CSpinner({ loading }) {
  return (
    loading && (
      <div className="overlay">
        <Spinner
          animation="border"
          variant="primary"
          role="status"
          backdrop="static"
        >
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    )
  );
}
