import {
  GET_TRESTLE_FILTERS_REQUEST,
  SINGLE_TRESTLE_IMPORT_REQUEST,
  SINGLE_TRESTLE_IMPORT_SUCCESS,
  SINGLE_TRESTLE_IMPORT_FAILED,
  MULTIPLE_TRESTLE_FILTER_IMPORT_REQUEST,
  MULTIPLE_TRESTLE_FILTER_IMPORT_SUCCESS,
  MULTIPLE_TRESTLE_FILTER_IMPORT_FAILED,
  MULTIPLE_TRESTLE_FILTER_RESET,
} from "../actions/TrestleTypes";

const initialState = {
  trestleFilterData: [],
  imported: null,
  trestleRequest: null,
};

export default function TrestleReducer(state = initialState, actions) {
  switch (actions.type) {
    case GET_TRESTLE_FILTERS_REQUEST:
      return {
        ...state,
        trestleFilterData: actions.payload,
      };
    case SINGLE_TRESTLE_IMPORT_REQUEST:
      return {
        ...state,
        trestleRequest: true,
      };
    case SINGLE_TRESTLE_IMPORT_SUCCESS:
      return {
        ...state,
        trestleRequest: false,
      };
    case SINGLE_TRESTLE_IMPORT_FAILED:
      return {
        ...state,
        trestleRequest: false,
      };
    case MULTIPLE_TRESTLE_FILTER_IMPORT_REQUEST:
      return {
        ...state,
        trestleRequest: true,
      };
    case MULTIPLE_TRESTLE_FILTER_IMPORT_SUCCESS:
      return {
        ...state,
        imported: true,
        trestleRequest: false,
      };
    case MULTIPLE_TRESTLE_FILTER_IMPORT_FAILED:
      return {
        ...state,
        imported: false,
        trestleRequest: false,
      };
    case MULTIPLE_TRESTLE_FILTER_RESET:
      return {
        ...state,
        imported: false,
      };
    default:
      return state;
  }
}
