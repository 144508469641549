import {
  getPropertyAnalyticsService,
  getPropertyListService,
  getPropertyDetailService,
  getActiveVSSoldPropertiesService,
  getSoldPropertiesCountService,
  getStatusWisePropertiesCountService,
} from "../../services/PropertyService";
import {
  PROPERTY_ANALYTICS_REQUEST,
  PROPERTY_ANALYTICS_SUCCESS,
  PROPERTY_ANALYTICS_FAILED,
  PROPERTY_LIST_REQUEST,
  PROPERTY_LIST_SUCCESS,
  PROPERTY_LIST_FAILED,
  PROPERTY_DETAIL_REQUEST,
  PROPERTY_DETAIL_SUCCESS,
  PROPERTY_DETAIL_FAILED,
  PROPERTY_ACTIVE_VS_SOLD_REQUEST,
  PROPERTY_ACTIVE_VS_SOLD_SUCCESS,
  PROPERTY_ACTIVE_VS_SOLD_FAILED,
  PROPERTY_SOLD_REQUEST,
  PROPERTY_SOLD_SUCCESS,
  PROPERTY_SOLD_FAILED,
  PROPERTY_STATUS_WISE_COUNT_REQUEST,
  PROPERTY_STATUS_WISE_COUNT_SUCCESS,
  PROPERTY_STATUS_WISE_COUNT_FAILED,
} from "./PropertyTypes";

export function getPropertyAnalytics(postData) {
  return (dispatch, getState) => {
    dispatch(request());
    getPropertyAnalyticsService(postData)
      .then((response) => {
        if (response.status) {
          dispatch(success(response.data.data));
        }
      })
      .catch((error) => {
        dispatch(failure(error.response.data.message));
      });
  };

  function request() {
    return { type: PROPERTY_ANALYTICS_REQUEST };
  }
  function success(payload) {
    return { type: PROPERTY_ANALYTICS_SUCCESS, payload: payload };
  }
  function failure(error) {
    return { type: PROPERTY_ANALYTICS_FAILED, error };
  }
}
export function getPropertyList(postData) {
  return (dispatch, getState) => {
    dispatch(request());
    getPropertyListService(postData)
      .then((response) => {
        if (response.status) {
          dispatch(success(response.data.data));
        }
      })
      .catch((error) => {
        dispatch(failure(error.response.data.message));
      });
  };

  function request() {
    return { type: PROPERTY_LIST_REQUEST };
  }
  function success(payload) {
    return { type: PROPERTY_LIST_SUCCESS, payload: payload };
  }
  function failure(error) {
    return { type: PROPERTY_LIST_FAILED, error };
  }
}
export function getPropertyDetail(postData) {
  return (dispatch, getState) => {
    dispatch(request());
    getPropertyDetailService(postData)
      .then((response) => {
        if (response.status) {
          dispatch(success(response.data.data));
        }
      })
      .catch((error) => {
        console.log("error getPropertyDetail", error);
        dispatch(failure(error.response.data.message));
      });
  };

  function request() {
    return { type: PROPERTY_DETAIL_REQUEST };
  }
  function success(payload) {
    return { type: PROPERTY_DETAIL_SUCCESS, payload: payload };
  }
  function failure(error) {
    return { type: PROPERTY_DETAIL_FAILED, error };
  }
}

export function getActiveVSSoldProperties(postData) {
  return (dispatch, getState) => {
    dispatch(request());
    getActiveVSSoldPropertiesService(postData)
      .then((response) => {
        if (response.status) {
          dispatch(success(response.data.data));
        }
      })
      .catch((error) => {
        console.log("error getActiveVSSoldProperties", error);
        dispatch(failure(error.response.data.message));
      });
  };

  function request() {
    return { type: PROPERTY_ACTIVE_VS_SOLD_REQUEST };
  }
  function success(payload) {
    return { type: PROPERTY_ACTIVE_VS_SOLD_SUCCESS, payload: payload };
  }
  function failure(error) {
    return { type: PROPERTY_ACTIVE_VS_SOLD_FAILED, error };
  }
}

export function getSoldPropertiesCount(postData) {
  return (dispatch, getState) => {
    dispatch(request());
    getSoldPropertiesCountService(postData)
      .then((response) => {
        if (response.status) {
          dispatch(success(response.data.data));
        }
      })
      .catch((error) => {
        console.log("error getSoldPropertiesCount", error);
        dispatch(failure(error.response.data.message));
      });
  };

  function request() {
    return { type: PROPERTY_SOLD_REQUEST };
  }
  function success(payload) {
    return { type: PROPERTY_SOLD_SUCCESS, payload: payload };
  }
  function failure(error) {
    return { type: PROPERTY_SOLD_FAILED, error };
  }
}

export function getStatusWisePropertiesCount(postData) {
  return (dispatch, getState) => {
    dispatch(request());
    getStatusWisePropertiesCountService(postData)
      .then((response) => {    
        if (response.status) {
          dispatch(success(response.data.data));
        }
      })      
      .catch((error) => {
        console.log("error getSoldPropertiesCount", error);
        dispatch(failure(error.response.data.message));
      }); 
  };

  function request() {
    return { type: PROPERTY_STATUS_WISE_COUNT_REQUEST };
  }
  function success(payload) {
    return { type: PROPERTY_STATUS_WISE_COUNT_SUCCESS, payload: payload };
  }
  function failure(error) {
    return { type: PROPERTY_STATUS_WISE_COUNT_FAILED, error };
  }
}
