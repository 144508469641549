// Toaster.js

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Initialize toast configuration
toast.configure();

// Common toaster function
const Toast = (message, type = "info") => {
  toast[type](message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 2000, // Close after 2 seconds
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default Toast;
