import axiosInstance from "./AxiosInstance";
import { URLS } from "../utils/constants";

export function getPropertyAnalyticsService(postData) {
  return axiosInstance({
    method: "POST",
    url: URLS.V1.PROPERTY_ANALYTICS,
    baseURL: process.env.REACT_APP_PROPERTY_API_URL,
    data: postData,
  });
}

export function getPropertyListService(postData) {
  return axiosInstance({
    method: "POST",
    url: URLS.V1.PROPERTY_LIST,
    baseURL: process.env.REACT_APP_PROPERTY_API_URL,
    data: postData,
  });
}
export function getPropertyDetailService(postData) {
  return axiosInstance({
    method: "POST",
    url: URLS.V1.PROPERTY_DETAILS,
    baseURL: process.env.REACT_APP_PROPERTY_API_URL,
    data: postData,
  });
}
export function getActiveVSSoldPropertiesService(postData) {
  return axiosInstance({
    method: "POST",
    url: URLS.V1.PROPERTY_ACTIVE_VS_SOLD,
    baseURL: process.env.REACT_APP_PROPERTY_API_URL,
    data: postData,
  });
}
export function getSoldPropertiesCountService(postData) {
  return axiosInstance({
    method: "POST",
    url: URLS.V1.PROPERTY_SOLD_STATS,
    baseURL: process.env.REACT_APP_PROPERTY_API_URL,
    data: postData,
  });
}
export function getStatusWisePropertiesCountService(postData) {
  return axiosInstance({
    method: "POST",
    url: URLS.V1.PROPERTY_STATUS_WISE_COUNT,
    baseURL: process.env.REACT_APP_PROPERTY_API_URL,
    data: postData,
  });
}
