export const URLS = {
  V1: {
    LOGIN: "v1/auth/login",
    LOGOUT: "v1/auth/logout",
    MLS_FILTERS: "v1/mlsgrid/filters/",
    MLS_IMPORT: "v1/mlsgrid/import/",
    MLS_SINGLE_IMPORT: "v1/mlsgrid/import/single",
    TRESTLE_FILTERS: "v1/trestle/filters/",
    TRESTLE_IMPORT: "v1/trestle/import/",
    TRESTLE_SINGLE_IMPORT: "v1/trestle/import/single",
    PROPERTY_ANALYTICS: "data/getAnalytics",
    PROPERTY_LIST: "data/getPropertyList",
    PROPERTY_DETAILS: "data/getPropertyDetail",
    PROPERTY_ACTIVE_VS_SOLD: "data/getactivevssold",
    PROPERTY_SOLD_STATS: "data/getsoldstat",
    PROPERTY_STATUS_WISE_COUNT: "data/getstatuswisecount",
  },
};
