export const GET_TRESTLE_FILTERS_REQUEST = "GET_TRESTLE_FILTERS_REQUEST";

export const MULTIPLE_TRESTLE_FILTER_IMPORT_REQUEST = "MULTIPLE_TRESTLE_FILTER_IMPORT_REQUEST";
export const MULTIPLE_TRESTLE_FILTER_IMPORT_SUCCESS = "MULTIPLE_TRESTLE_FILTER_IMPORT_SUCCESS";
export const MULTIPLE_TRESTLE_FILTER_IMPORT_FAILED = "MULTIPLE_TRESTLE_FILTER_IMPORT_FAILED";
export const MULTIPLE_TRESTLE_FILTER_RESET = "MULTIPLE_TRESTLE_FILTER_RESET";

export const SINGLE_TRESTLE_IMPORT_REQUEST = "SINGLE_TRESTLE_IMPORT_REQUEST";
export const SINGLE_TRESTLE_IMPORT_SUCCESS = "SINGLE_TRESTLE_IMPORT_SUCCESS";
export const SINGLE_TRESTLE_IMPORT_FAILED = "SINGLE_TRESTLE_IMPORT_FAILED";
