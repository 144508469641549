import {
  GET_FILTERS_REQUEST,
  MULTIPLE_FILTER_IMPORT_REQUEST,
  MULTIPLE_FILTER_IMPORT_SUCCESS,
  MULTIPLE_FILTER_IMPORT_FAILED,
  MULTIPLE_FILTER_RESET,
  SINGLE_IMPORT_REQUEST,
  SINGLE_IMPORT_SUCCESS,
  SINGLE_IMPORT_FAILED,
} from "../actions/MLSGridTypes";

const initialState = {
  filterData: [],
  imported: null,
  mlsRequest: null,
};

export default function MLSGridReducer(state = initialState, actions) {
  switch (actions.type) {
    case GET_FILTERS_REQUEST:
      return {
        ...state,
        filterData: actions.payload,
      };
    case SINGLE_IMPORT_REQUEST:
      return {
        ...state,
        mlsRequest: true,
      };
    case SINGLE_IMPORT_SUCCESS:
      return {
        ...state,
        mlsRequest: false,
      };
    case SINGLE_IMPORT_FAILED:
      return {
        ...state,
        mlsRequest: false,
      };
    case MULTIPLE_FILTER_IMPORT_REQUEST:
      return {
        ...state,
        mlsRequest: true,
      };
    case MULTIPLE_FILTER_IMPORT_SUCCESS:
      return {
        ...state,
        imported: true,
        mlsRequest: false,
      };
    case MULTIPLE_FILTER_IMPORT_FAILED:
      return {
        ...state,
        imported: false,
        mlsRequest: false,
      };
    case MULTIPLE_FILTER_RESET:
      return {
        ...state,
        imported: false,
      };
    default:
      return state;
  }
}
