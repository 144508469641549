import axios from "axios";
import { store } from "../store/store";
import swal from "sweetalert";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const { accessToken } = state.auth.auth;
  config.params = config.params || {};
  config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("userDetails");
      swal("Timeout", "Session Timeout", "error");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
