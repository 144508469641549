import React, { Fragment } from "react";
import PageTItle from "../../layouts/PageTitle";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { singleImportRequest } from "../../../store/actions/MLSGridActions";
import CSpinner from "../PluginsMenu/Spinner/CSpinner";

const SingleMLS = () => {
  const textInput = React.useRef();
  const [singleImportId, setSingleImportId] = useState("");
  let errorsObj = { singleImportErr: "" };
  const [errors, setErrors] = useState(errorsObj);
  const { mlsRequest } = useSelector((state) => {
    return state.mlsGrid;
  });
  const dispatch = useDispatch();

  const resetData = () => {
    textInput.current.value = "";
    setSingleImportId(null);
  };

  // const hasSpecialChar = (singleImportId) => {
  //   if (/^[a-zA-Z0-9,.\s]+$/.test(singleImportId)) return false;
  //   return true;
  // };

  const handleSubmit = () => {
    const errorObj = { ...errorsObj };
    let error = false;
    if (singleImportId == "") {
      errorObj.singleImportErr = "Please enter valid value";
      error = true;
    } /*else if (hasSpecialChar(singleImportId)) {
      errorObj.singleImportErr = "Special character is not allowed";
      error = true;
    }*/
    setErrors(errorObj);
    if (error) return;

    dispatch(
      singleImportRequest({ ids: singleImportId.split(",") }, resetData)
    );
    setSingleImportId(null);
  };

  return (
    <Fragment>
      <PageTItle
        activeMenu="Filter"
        motherMenu="Single MLS"
        pageContent="Filter"
      />
      <CSpinner loading={mlsRequest} />
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Single MLS Filter</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <div className="form-group mb-4">
                  <label>MLS# Import</label>
                  <input
                    ref={textInput}
                    type="text"
                    className="form-control input-default "
                    placeholder=""
                    onChange={(e) => setSingleImportId(e.target.value)}
                  />
                  {errors.singleImportErr && (
                    <div className="text-danger fs-12">
                      {errors.singleImportErr}
                    </div>
                  )}
                </div>
                <div className="text-end">
                  <Button
                    // className="pull-right"
                    // bsStyle="danger"
                    // bsSize="small"
                    onClick={handleSubmit}
                  >
                    Import
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SingleMLS;
