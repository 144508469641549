import axiosInstance from './AxiosInstance';
import {URLS} from '../utils/constants';

export function getFilterData() {
    return axiosInstance.get(URLS.V1.MLS_FILTERS);
}

export function filterMultiple(postData) {
    return axiosInstance.post(URLS.V1.MLS_IMPORT, postData);
}
export function singleImport(postData) {
    return axiosInstance.post(URLS.V1.MLS_SINGLE_IMPORT, postData);
}