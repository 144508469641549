export const PROPERTY_ANALYTICS_REQUEST = "PROPERTY_ANALYTICS_REQUEST";
export const PROPERTY_ANALYTICS_SUCCESS = "PROPERTY_ANALYTICS_SUCCESS";
export const PROPERTY_ANALYTICS_FAILED = "PROPERTY_ANALYTICS_FAILED";

export const PROPERTY_LIST_REQUEST = "PROPERTY_LIST_REQUEST";
export const PROPERTY_LIST_SUCCESS = "PROPERTY_LIST_SUCCESS";
export const PROPERTY_LIST_FAILED = "PROPERTY_LIST_FAILED";

export const PROPERTY_DETAIL_REQUEST = "PROPERTY_DETAIL_REQUEST";
export const PROPERTY_DETAIL_SUCCESS = "PROPERTY_DETAIL_SUCCESS";
export const PROPERTY_DETAIL_FAILED = "PROPERTY_DETAIL_FAILED";

export const PROPERTY_ACTIVE_VS_SOLD_REQUEST =
  "PROPERTY_ACTIVE_VS_SOLD_REQUEST";
export const PROPERTY_ACTIVE_VS_SOLD_SUCCESS =
  "PROPERTY_ACTIVE_VS_SOLD_SUCCESS";
export const PROPERTY_ACTIVE_VS_SOLD_FAILED = "PROPERTY_ACTIVE_VS_SOLD_FAILED";

export const PROPERTY_SOLD_REQUEST = "PROPERTY_SOLD_REQUEST";
export const PROPERTY_SOLD_SUCCESS = "PROPERTY_SOLD_SUCCESS";
export const PROPERTY_SOLD_FAILED = "PROPERTY_SOLD_FAILED";

export const PROPERTY_STATUS_WISE_COUNT_REQUEST =
  "PROPERTY_STATUS_WISE_COUNT_REQUEST";
export const PROPERTY_STATUS_WISE_COUNT_SUCCESS =
  "PROPERTY_STATUS_WISE_COUNT_SUCCESS";
export const PROPERTY_STATUS_WISE_COUNT_FAILED =
  "PROPERTY_STATUS_WISE_COUNT_FAILED";
