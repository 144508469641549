import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import socket from "../../socket";
import Toast from "../../utils/Toastr";

const SocketImportNotifier = () => {
  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.on("importStatus", (data) => {
      Toast(data.message, data?.status ? "success" : "error");
    });
  }, [socket]);
  return <ToastContainer />;
};

export default SocketImportNotifier;
