import { formatIsoTimeString } from "@fullcalendar/core";
import {
  PROPERTY_ANALYTICS_REQUEST,
  PROPERTY_ANALYTICS_SUCCESS,
  PROPERTY_ANALYTICS_FAILED,
  PROPERTY_LIST_REQUEST,
  PROPERTY_LIST_SUCCESS,
  PROPERTY_LIST_FAILED,
  PROPERTY_DETAIL_REQUEST,
  PROPERTY_DETAIL_SUCCESS,
  PROPERTY_DETAIL_FAILED,
  PROPERTY_ACTIVE_VS_SOLD_REQUEST,
  PROPERTY_ACTIVE_VS_SOLD_SUCCESS,
  PROPERTY_ACTIVE_VS_SOLD_FAILED,
  PROPERTY_SOLD_REQUEST,
  PROPERTY_SOLD_SUCCESS,
  PROPERTY_SOLD_FAILED,
  PROPERTY_STATUS_WISE_COUNT_REQUEST,
  PROPERTY_STATUS_WISE_COUNT_SUCCESS,
  PROPERTY_STATUS_WISE_COUNT_FAILED,
} from "../actions/PropertyTypes";

const initialState = {
  propertyAnalytics: [
    {
      avgsale: "0",
      avgdays: 0,
      numproperty: 0,
    },
  ],
  properties: [],
  loadingProperties: null,
  propertyDetail: {
    property: null,
    media: null,
  },
  isValidProperty: null,
  imported: null,
  activevssoldProperties: [],
  soldProperties: [],
  statuswiseProperties: [],
};

const formatChartData = (data) => {
  // Get the current year
  const currentYear = new Date().getFullYear();

  // Combine "Pending" and "Active" counts if they fall in the same month
  const combinedData = {};

  data.forEach((entry) => {
    const key = `${entry.year}-${entry.month}`;
    if (!combinedData[key]) {
      combinedData[key] = {
        year: entry.year,
        month: entry.month,
        count: 0,
      };
    }
    combinedData[key].count += entry.count;
    combinedData[key].status = "Combined";
  });

  // Filter for the last year and consider both "Combined" and "Active"
  const lastYearCombinedData = Object.values(combinedData).filter(
    (entry) => entry.year === currentYear - 1
  );

  // Prepare an array of last year's combined data month-wise
  let formattedData = Array(12).fill(0);

  lastYearCombinedData.forEach((entry) => {
    formattedData[entry.month - 1] += entry.count;
  });

  return formattedData;
};

export default function PropertyReducer(state = initialState, actions) {
  switch (actions.type) {
    case PROPERTY_ANALYTICS_REQUEST:
      return {
        ...state,
      };
    case PROPERTY_ANALYTICS_SUCCESS:
      return {
        ...state,
        propertyAnalytics: actions.payload,
      };
    case PROPERTY_ANALYTICS_FAILED:
      return {
        ...state,
      };
    case PROPERTY_LIST_REQUEST:
      return {
        ...state,
        loadingProperties: true
      };
    case PROPERTY_LIST_SUCCESS:
      return {
        ...state,
        properties: actions.payload,
        loadingProperties: false
      };
    case PROPERTY_LIST_FAILED:
      return {
        ...state,
        loadingProperties: false
      };
    case PROPERTY_DETAIL_REQUEST:
      return {
        ...state,
        isValidProperty: null,
      };
    case PROPERTY_DETAIL_SUCCESS:
      let details = {
        property: null,
        media: null,
      };

      let isValid = false;

      if (actions.payload && actions.payload.length > 0) {
        let { media, property } = actions.payload[0].result;
        if (media.length > 0 && media[0] != null) {
          isValid = true;
          details.media = media[0];
        }
        if (
          property.length > 0 &&
          property[0] != null &&
          property[0].length > 0
        ) {
          isValid = true;
          details.property = property[0][0];
        }
      }
      return {
        ...state,
        propertyDetail: details,
        isValidProperty: isValid,
      };
    case PROPERTY_DETAIL_FAILED:
      return {
        ...state,
        isValidProperty: null,
      };
    case PROPERTY_ACTIVE_VS_SOLD_REQUEST:
      return {
        ...state,
      };
    case PROPERTY_ACTIVE_VS_SOLD_SUCCESS:
      let lastYearCombinedDataMonthWise = [];
      if (actions.payload && actions.payload.length > 0) {
        lastYearCombinedDataMonthWise = formatChartData(actions.payload);
      }
      return {
        ...state,
        activevssoldProperties: lastYearCombinedDataMonthWise,
      };
    case PROPERTY_ACTIVE_VS_SOLD_FAILED:
      return {
        ...state,
      };
    case PROPERTY_SOLD_REQUEST:
      return {
        ...state,
      };
    case PROPERTY_SOLD_SUCCESS:
      let finalData = [];
      if (actions.payload && actions.payload.length > 0) {
        finalData = formatChartData(actions.payload);
      }
      return {
        ...state,
        soldProperties: finalData,
      };
    case PROPERTY_SOLD_FAILED:
      return {
        ...state,
      };
    case PROPERTY_STATUS_WISE_COUNT_REQUEST:
      return {
        ...state,
      };
    case PROPERTY_STATUS_WISE_COUNT_SUCCESS:
      return {
        ...state,
        statuswiseProperties: actions.payload,
      };
    case PROPERTY_STATUS_WISE_COUNT_FAILED:
      return {
        ...state,
      };
    default:
      return state;
  }
}
